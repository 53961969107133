var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column"},[_c('v-overlay',{attrs:{"value":_vm.loadingRunning}},[_vm._t("loader",function(){return [_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})]},null,{loading: _vm.loadingRunning})],2),_c('v-sheet',{staticClass:"flex-grow-1 flex-shrink-0",attrs:{"height":"fit-content"}},[_c('v-toolbar',{attrs:{"flat":"","height":"fit-content"}},[_vm._t("toolbar",function(){return [_c('v-btn',{staticClass:"mr-4",attrs:{"outlined":"","color":"grey darken-2"},on:{"click":_vm.setToday}},[_vm._v("Oggi")]),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.prev}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-left")])],1),_c('v-btn',{attrs:{"fab":"","text":"","small":"","color":"grey darken-2"},on:{"click":_vm.next}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chevron-right")])],1),(_vm.$refs.calendar)?_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$refs.calendar.title))]):_vm._e(),_vm._t("toolbar-append",null,null,{ 
          calendar: _vm.$refs.calendar, 
          prev: _vm.prev, 
          next: _vm.next 
        })]},null,{
        calendar: _vm.$refs.calendar,
        prev: _vm.prev,
        next: _vm.next
      })],2)],1),_c('div',{staticClass:"flex-shrink-1 calendar-container"},[_c('v-calendar',{ref:"calendar",attrs:{"locale":_vm.locale,"color":"primary","type":"week","events":_vm.events,"event-end":_vm.eventEnd,"event-start":_vm.eventStart,"event-name":_vm.eventName,"event-timed":_vm.eventTimed,"event-color":_vm.getEventColor,"event-text-color":_vm.getEventTextColor,"event-ripple":false},on:{"change":_vm.fetchEvents,"mousedown:event":_vm.startDrag,"touchstart:event":_vm.startDrag,"mousedown:time":function($event){return _vm.startTime($event)},"touchstart:time":function($event){return _vm.touchStartTime($event)},"mousemove:time":_vm.mouseMove,"touchmove:time":_vm.touchMove,"contextmenu:event":_vm.openMenuDialog,"touchend:event":_vm.endTouch,"mouseup:time":_vm.endDrag},nativeOn:{"mouseleave":function($event){return _vm.cancelDrag.apply(null, arguments)}},scopedSlots:_vm._u([(_vm.showTimeline)?{key:"day-body",fn:function({ date, week }){return [(!!_vm.dragEvent)?_c('div',{staticClass:"v-event-time",class:{ first: date === week[0].date },style:({ top: _vm.eventY, '--background-color': _vm.getEventColor(_vm.dragEvent) })},[_vm._v(_vm._s(_vm.eventTime))]):_vm._e(),_c('div',{staticClass:"v-current-time",class:{ first: date === week[0].date },style:({ top: _vm.nowY })})]}}:null,{key:"event",fn:function({ event, timed, eventSummary }){return [_vm._t("default",function(){return [_c('div',{staticClass:"v-event-draggable",staticStyle:{"height":"100%"},domProps:{"innerHTML":_vm._s(eventSummary())}})]},null,{event, timed, eventSummary}),(timed)?_c('div',{staticClass:"v-event-drag-bottom",on:{"mousedown":function($event){$event.stopPropagation();return _vm.extendBottom(event)},"touchstart":function($event){$event.stopPropagation();return _vm.extendBottom(event)}}}):_vm._e()]}}],null,true),model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }