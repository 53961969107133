<template>
  <div>
    <v-row>
      <v-col>
        <OperatorsAutocomplete 
          v-model="selectedInvitees"
          item-value="id"
          multiple
          return-object
        >
          <template slot="append-outer">
            <v-btn 
              class="ml-1"
              outlined
              @click="addInvitees"  
            >
              Aggiungi
            </v-btn>
          </template>
        </OperatorsAutocomplete>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class="pa-0">
        <v-progress-circular
          indeterminate
          v-if="this.loadingInvitees"
        ></v-progress-circular>
        <div v-else>
          <v-list v-if="invitees.length > 0" style="max-height: 70vh">
            <template v-for="invitee in invitees">
              <v-list-item v-bind:key="invitee.id">
                <v-list-item-title>
                  {{invitee.firstname}} {{invitee.lastname}}
                </v-list-item-title>
                <v-list-item-action>
                  <v-btn icon @click="cancelInvitation(invitee)">
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-divider v-bind:key="invitee.id + '-divider'"></v-divider>
            </template>
          </v-list>
          <div
            v-else
            class="text-center text-caption"
          >
            Nessun invito
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import OperatorsAutocomplete from '@/components/common/OperatorsAutocomplete'
import calendarManagment from '@/services/calendar/calendar.service.js'
import _ from 'lodash'

export default {
  name: 'InviteeForm',
  components: {
    OperatorsAutocomplete
  },
  data: function() {
    return {
      selectedInvitees: [],
      invitees: [],
      loadingInvitees: false
    }
  },
  props: {
    eventId: {
      type: Number,
      default: undefined
    },
  },
  mounted: function() {
    if(this.eventId) {
      this.fetchInvitees()
    }
  },
  methods: {
    fetchInvitees() {
        this.loadingInvitees = true
        calendarManagment.getInvitees({id: this.eventId}).then((invitees) => {
          this.invitees = invitees.map(el => { return {
            id: el.operatorId,
            firstname: el.firstname,
            lastname: el.lastname
          }})
          this.loadingInvitees = false
        })
    },
    cancelInvitation(invitee) {
      let confirmed = confirm('Sei sicuro di voler eliminare l\'invito?')

      if(confirmed) {
        calendarManagment.cancelInvitee({id: this.eventId}, invitee).then(() => {
          this.invitees = _.remove(this.invitees, (el) => { return el.id != invitee.id})
          this.$emit('change-invitees', this.invitees)
        })
      }
    },
    addInvitees() {
      this.loadingForm = true
      calendarManagment.addInvitees({id: this.eventId}, this.selectedInvitees).then(() => {
        this.invitees = _.unionBy(this.invitees, this.selectedInvitees, 'id')
        this.$emit('change-invitees', this.invitees)
        this.loadingForm = false
        this.selectedInvitees = []
      })
    },
  },
  watch: {
    eventId(newVal) {
      if(newVal) {
        this.fetchInvitees()
      }
    }
  }
}
</script>

<style>

</style>