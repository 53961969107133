var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ResponsiveCardSelector',{attrs:{"items":_vm.operators,"card-max-height":"75","card-min-height":"75","card-max-width":"75","card-min-width":"75","loading":_vm.localLoading || _vm.loading,"disabled":_vm.disabled,"return-object":_vm.returnObject,"item-color":_vm.colorize ? (operator) => { return operator.color } : undefined},on:{"input":function($event){return _vm.$emit('input', _vm.localValue)},"click":function($event){return _vm.$emit('click', $event)}},scopedSlots:_vm._u([{key:"item",fn:function({item, cardMinHeight, textColor}){return [(_vm.showOnlyNameOperator)?_c('div',{staticClass:"pa-2 d-flex flex-column justify-center align-center",style:({'min-height': cardMinHeight.indexOf('px') != -1 ? cardMinHeight : cardMinHeight + 'px'})},[_c('div',{staticClass:"text-center",style:({
        color: _vm.colorize ? textColor : undefined,
        fontSize: '12px'
      })},[_vm._v(" "+_vm._s(item.firstname)+" ")])]):_c('div',{staticClass:"pa-2 d-flex flex-column justify-center align-center",style:({'min-height': cardMinHeight.indexOf('px') != -1 ? cardMinHeight : cardMinHeight + 'px'})},[_c('div',{staticClass:"text-center",style:({
        color: _vm.colorize ? textColor : undefined,
        fontSize: '12px'
      })},[_vm._v(" "+_vm._s(item.firstname)+" "+_vm._s(item.lastname)+" ")])])]}}]),model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=$$v},expression:"localValue"}})
}
var staticRenderFns = []

export { render, staticRenderFns }