<template>
  <v-container fluid class="pa-0 mt-2">
    <v-row v-if="loading">
      <v-col class="d-flex justify-center align-center">
        <v-progress-circular indeterminate></v-progress-circular>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col class="d-flex align-center justify-center">
        <SingleValueViewer
          label="Ultimo servizio"
          :value="!!lastService ? lastService.name : 'Non specificato'"
          class="mx-2"
          outline
        ></SingleValueViewer>
        <SingleValueViewer
          label="Ultimo operatore"
          :value="
            !!lastOperator
              ? `${lastOperator.firstname} ${lastOperator.lastname}`
              : 'Non specificato'
          "
          class="mx-2"
          outline
        >
          <template v-slot:value>
            <div class="d-flex flex-wrap">
              <OperatorChip
                v-if="!!lastOperator"
                class="mt-1 mr-1"
                :value="lastOperator"
              ></OperatorChip>
              <div class="text-h5 font-weight-black" v-else>
                Non specificato
              </div>
              <v-progress-circular indeterminate v-else></v-progress-circular>
            </div>
          </template>
        </SingleValueViewer>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex justify-center align-center">
        <v-card>
          <DataTable
            :headers="headers"
            :disable-pagination="true"
            :loading="loading || loadingHeaders"
            loading-text="Caricamento ultimi 5 servizi"
            :items="lastFiveServicesOperators"
            item-key="id"
            :show-select="false"
            :show-actions="false"
          >
            <template v-slot:custom-operator="{ item }">
              <OperatorChip
                class="mt-1 mr-1"
                :value="{
                  id: item.operators[0].operatorId,
                  firstname: item.operators[0].firstname,
                  lastname: item.operators[0].lastname,
                  color: item.operators[0].color,
                  textColor: item.operators[0].textColor,
                  avatar: item.operators[0].avatar,
                }"
              ></OperatorChip>
              <v-chip class="mt-1 mb-1" v-if="item.operators.length > 1">
                + {{ item.operators.length }}</v-chip
              >
            </template>
            <template> </template>
          </DataTable>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import calendarService from "@/services/calendar/calendar.service.js";
import SingleValueViewer from "@/components/common/SingleValueViewer.vue";
import OperatorChip from "@/components/common/OperatorChip.vue";
import DataTable from "@/components/common/TypeDataTable.vue";

export default {
  name: "CustomerAnalytics",
  components: {
    SingleValueViewer,
    OperatorChip,
    DataTable,
  },
  data: function() {
    return {
      loading: false,
      lastService: undefined,
      lastOperator: undefined,
      lastFiveServicesOperators: [],
      loadingHeaders: false,
      headers: [],
    };
  },
  props: {
    customer: {
      type: Object,
    },
  },
  mounted: function() {
    this.loadHeaders();
    this.loadAnalytics();
  },
  methods: {
    loadAnalytics() {
      if (!!this.customer) {
        this.loading = true;
        calendarService.analyticsCustomer(this.customer).then((results) => {
          this.loading = false;
          this.lastService = results.lastService;
          this.lastOperator = results.lastOperator;
          for (let i = 0; i < results.lastFiveServicesOperators.length; i++) {
            results.lastFiveServicesOperators[i].startDate = new Date(
              results.lastFiveServicesOperators[i].startDate
            );
          }
          this.lastFiveServicesOperators = results.lastFiveServicesOperators;
        });
      }
    },
    loadHeaders() {
      this.loadingHeaders = true;
      calendarService.customerAnalyticsFields().then((headers) => {
        this.headers = headers;
        this.loadingHeaders = false;
      });
    },
  },
  watch: {
    customer() {
      this.loadAnalytics();
    },
  },
  computed: {},
};
</script>

<style></style>
