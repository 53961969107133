var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('h3',[_vm._v("Impostazioni Carpe Diem")])]),_c('v-row',{staticClass:"d-flex align-center justify-space-between"},[_c('div',{staticClass:"pr-2"},[_vm._v(" Attivo nelle prossime ")]),_c('v-select',{staticClass:"pl-2",staticStyle:{"width":"50%"},attrs:{"items":[{text: '24h', value: 1}, {text: '48h', value: 2}, {text: '72h', value: 3}],"item-text":"text","item-value":"value","dense":"","rounded":"","filled":"","hide-details":""},model:{value:(_vm.validForNextDays),callback:function ($$v) {_vm.validForNextDays=$$v},expression:"validForNextDays"}})],1),_c('v-row',{staticClass:"d-flex align-center justify-space-between"},[_c('div',{staticClass:"pr-2"},[_vm._v(" Attivo/Disattivo ")]),_c('v-switch',{staticClass:"pl-2",attrs:{"inset":"","color":"secondary"},model:{value:(_vm.enabled),callback:function ($$v) {_vm.enabled=$$v},expression:"enabled"}})],1)],1),_c('v-col',{staticStyle:{"overflow-y":"auto","overflow-x":"hidden","padding":"0%"},attrs:{"cols":"12"}},[_c('v-row',{staticClass:"d-flex align-center justify-space-between ma-0"},[_c('h3',[_vm._v("Orari")])]),_c('div',{staticStyle:{"height":"42vh","overflow-x":"auto","overflow-y":"hidden","margin-top":"15px","display":"flex","flex-direction":"column","align-items":"center"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"width":"fit-content","gap":"15px"}},[_c('div',{staticClass:"d-flex justify-center",style:({
            'padding': '5px',
            'width': _vm.$vuetify.breakpoint.xs ? '27dvw' :
              _vm.$vuetify.breakpoint.sm ? '20dvw' :
              _vm.$vuetify.breakpoint.md ? '14dvw' : 
              _vm.$vuetify.breakpoint.xl ? '6dvw' : '10dvw'
            })},[_vm._v(" Da ")]),_c('div',{staticClass:"d-flex justify-center",style:({
            'padding': '5px',
            'width': _vm.$vuetify.breakpoint.xs ? '27dvw' :
              _vm.$vuetify.breakpoint.sm ? '20dvw' :
              _vm.$vuetify.breakpoint.md ? '14dvw' : 
              _vm.$vuetify.breakpoint.xl ? '6dvw' : '10dvw'
            })},[_vm._v(" A ")]),_c('div',{staticClass:"d-flex justify-center",style:({
            'padding': '5px',
            'width': _vm.$vuetify.breakpoint.xs ? '20dvw' :
              _vm.$vuetify.breakpoint.sm ? '13dvw' :
              _vm.$vuetify.breakpoint.md ? '10dvw' : 
              _vm.$vuetify.breakpoint.xl ? '5dvw' : '7dvw'
            })},[_vm._v(" Sconto ")]),_c('div',{style:({
            'padding': '5px',
            'width': _vm.$vuetify.breakpoint.xs ? '13dvw' :
              _vm.$vuetify.breakpoint.sm ? '9dvw' :
              _vm.$vuetify.breakpoint.md ? '7dvw' : 
              _vm.$vuetify.breakpoint.xl ? '3dvw' : '5dvw'
            })},[_vm._v(" Attivo ")]),_c('div',{style:({
            'padding': '5px',
            'width': _vm.$vuetify.breakpoint.xs ? '13dvw' :
              _vm.$vuetify.breakpoint.sm ? '9dvw' :
              _vm.$vuetify.breakpoint.md ? '7dvw' : 
              _vm.$vuetify.breakpoint.xl ? '3dvw' : '5dvw'
            })})]),_vm._l((_vm.configuredHours),function(configuredHour,idx){return _c('div',{key:idx,staticClass:"d-flex align-center",staticStyle:{"width":"fit-content","gap":"15px"}},[_c('div',{staticClass:"d-flex align-center",style:({
            'padding': '5px',
            'width': _vm.$vuetify.breakpoint.xs ? '27dvw' :
              _vm.$vuetify.breakpoint.sm ? '20dvw' :
              _vm.$vuetify.breakpoint.md ? '14dvw' : 
              _vm.$vuetify.breakpoint.xl ? '6dvw' : '10dvw'
            })},[_c('v-select',{attrs:{"items":_vm.hoursItems,"dense":"","filled":"","color":"secondary","rounded":"","hide-details":"","error":configuredHour.from > configuredHour.to},on:{"input":_vm.overlapCheck},model:{value:(configuredHour.from),callback:function ($$v) {_vm.$set(configuredHour, "from", $$v)},expression:"configuredHour.from"}})],1),_c('div',{staticClass:"d-flex align-center",style:({
            'padding': '5px',
            'width': _vm.$vuetify.breakpoint.xs ? '27dvw' :
              _vm.$vuetify.breakpoint.sm ? '20dvw' :
              _vm.$vuetify.breakpoint.md ? '14dvw' : 
              _vm.$vuetify.breakpoint.xl ? '6dvw' : '10dvw'
            })},[_c('v-select',{attrs:{"items":_vm.hoursItems,"dense":"","filled":"","color":"secondary","rounded":"","hide-details":"","error":configuredHour.from > configuredHour.to},on:{"input":_vm.overlapCheck},model:{value:(configuredHour.to),callback:function ($$v) {_vm.$set(configuredHour, "to", $$v)},expression:"configuredHour.to"}})],1),_c('div',{staticClass:"d-flex align-center",style:({
            'padding': '5px',
            'width': _vm.$vuetify.breakpoint.xs ? '20dvw' :
              _vm.$vuetify.breakpoint.sm ? '13dvw' :
              _vm.$vuetify.breakpoint.md ? '10dvw' : 
              _vm.$vuetify.breakpoint.xl ? '5dvw' : '7dvw'
            })},[_c('v-text-field',{attrs:{"type":"number","dense":"","filled":"","color":"secondary","error":configuredHour.discountPercentage < 0 || configuredHour.discountPercentage > 100,"rounded":"","hide-details":""},model:{value:(configuredHour.discountPercentage),callback:function ($$v) {_vm.$set(configuredHour, "discountPercentage", $$v)},expression:"configuredHour.discountPercentage"}})],1),_c('div',{staticClass:"d-flex align-center",style:({
            'padding': '5px',
            'width': _vm.$vuetify.breakpoint.xs ? '13dvw' :
              _vm.$vuetify.breakpoint.sm ? '9dvw' :
              _vm.$vuetify.breakpoint.md ? '7dvw' : 
              _vm.$vuetify.breakpoint.xl ? '3dvw' : '5dvw'
            })},[_c('v-switch',{staticClass:"pa-0 ma-0",attrs:{"inset":"","color":"secondary","hide-details":""},model:{value:(configuredHour.enabled),callback:function ($$v) {_vm.$set(configuredHour, "enabled", $$v)},expression:"configuredHour.enabled"}})],1),_c('div',{style:({
            'padding': '5px',
            'width': _vm.$vuetify.breakpoint.xs ? '13dvw' :
              _vm.$vuetify.breakpoint.sm ? '9dvw' :
              _vm.$vuetify.breakpoint.md ? '7dvw' : 
              _vm.$vuetify.breakpoint.xl ? '3dvw' : '5dvw'
            })},[_c('v-btn',{attrs:{"color":"error","outlined":"","icon":""},on:{"click":function($event){return _vm.removeConfiguredHours(idx)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)])}),_c('v-row',{staticClass:"d-flex justify-center",staticStyle:{"margin-top":"15px"}},[_c('v-btn',{attrs:{"color":"secondary","outlined":"","icon":""},on:{"click":_vm.addConfiguredHours}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],2)],1),_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"d-flex justify-center",staticStyle:{"width":"100%"}},[_c('v-btn',{attrs:{"color":"secondary","outlined":"","disabled":_vm.overlapError || _vm.configuredHours.some(c => c.discountPercentage < 0 || c.discountPercentage > 100)},on:{"click":_vm.save}},[_vm._v(" Salva Orari ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }