<template>
  <v-dialog 
    v-model="dialog"
    transition="scroll-y-transition"
    width="fit-content"
    min-height="fit-content"
    persistent
  >
    <template v-slot:activator="{on, attrs}">
      <v-avatar 
        v-on="on" 
        v-bind="attrs"
        v-if="selectedColorPresent"
        :color="selectedColor"
      ></v-avatar>
      <v-avatar 
        v-on="on" 
        v-bind="attrs"
        v-else
        color="white"
      >?</v-avatar>
    </template>
    <v-card height="fit-content" width="fit-content">
      <v-card-text class="pa-2">
        <v-color-picker
          v-model="selectedColor"
          show-swatches
        ></v-color-picker>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn 
          text
          @click="selectColor"
        >Conferma</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "StandardColorPicker",
  data: function() {
    return {
      dialog: false,
      selectedColor: this.value || '#E0E0E0',
    }
  },
  methods: {
    selectColor() {
      this.$emit('confirmed', this.selectedColor)
      this.dialog = false
    }
  },
  props: {
    value: {
      type: String,
      default: '#E0E0E0'
    }
  },
  computed: {
    selectedColorPresent() {
      return !!this.selectedColor
    }
  },
  watch: {
    selectedColor(newVal) {
      if(newVal != this.value) {
        this.$emit('input', newVal)
      }
    },
    value(newVal) {
      if(newVal != this.selectedColor) {
        this.selectedColor = newVal
      }
    },
  }
}
</script>

<style>

</style>