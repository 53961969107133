<template>
  <div>
    <v-chip 
      v-if="!!value" 
      :close="close"
      :disabled="disabled"
      :small="small"
      @click:close="$emit('close', value)"
      class="pl-0"
    >
      <OperatorAvatar 
        class="mr-1"
        v-model="value"
        :size="4"
      ></OperatorAvatar>
      <div v-if="showOnlyNameOperator">
        {{value.firstname}}
      </div>
      <div v-else>
        {{value.firstname}} {{value.lastname}}
      </div>
      
    </v-chip>
  </div>
</template>

<script>
import OperatorAvatar from './OperatorAvatar.vue'

export default {
  name: "OperatorChip",
  components: {
    OperatorAvatar
  },
  data: function() {
    return {}
  },
  props: {
    value: {

    },
    close: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showOnlyNameOperator: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>

</style>