var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0 mt-2",attrs:{"fluid":""}},[(_vm.loading)?_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center align-center"},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1)],1):_c('v-row',[_c('v-col',{staticClass:"d-flex align-center justify-center"},[_c('SingleValueViewer',{staticClass:"mx-2",attrs:{"label":"Ultimo servizio","value":!!_vm.lastService ? _vm.lastService.name : 'Non specificato',"outline":""}}),_c('SingleValueViewer',{staticClass:"mx-2",attrs:{"label":"Ultimo operatore","value":!!_vm.lastOperator
            ? `${_vm.lastOperator.firstname} ${_vm.lastOperator.lastname}`
            : 'Non specificato',"outline":""},scopedSlots:_vm._u([{key:"value",fn:function(){return [_c('div',{staticClass:"d-flex flex-wrap"},[(!!_vm.lastOperator)?_c('OperatorChip',{staticClass:"mt-1 mr-1",attrs:{"value":_vm.lastOperator}}):_c('div',{staticClass:"text-h5 font-weight-black"},[_vm._v(" Non specificato ")])],1)]},proxy:true}])})],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center align-center"},[_c('v-card',[_c('DataTable',{attrs:{"headers":_vm.headers,"disable-pagination":true,"loading":_vm.loading || _vm.loadingHeaders,"loading-text":"Caricamento ultimi 5 servizi","items":_vm.lastFiveServicesOperators,"item-key":"id","show-select":false,"show-actions":false},scopedSlots:_vm._u([{key:"custom-operator",fn:function({ item }){return [_c('OperatorChip',{staticClass:"mt-1 mr-1",attrs:{"value":{
                id: item.operators[0].operatorId,
                firstname: item.operators[0].firstname,
                lastname: item.operators[0].lastname,
                color: item.operators[0].color,
                textColor: item.operators[0].textColor,
                avatar: item.operators[0].avatar,
              }}}),(item.operators.length > 1)?_c('v-chip',{staticClass:"mt-1 mb-1"},[_vm._v(" + "+_vm._s(item.operators.length))]):_vm._e()]}}])},[void 0],2)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }